class AvatarPersonsListComponent extends Component {

    static name() {
        return "AvatarPersonsListComponent";
    }

    static componentName() {
        return "AvatarPersonsListComponent";
    }

    getProps() {
        return ['data', 'index', 'column'];
    }

    getComputed() {
        return  {
            url:function () {
                if(this.data.Avatar)
                    return this.data.Avatar;
                else return "../static/ecommerce/img/user.png";
            },
        };
    }
    //Comento pues cambie forma(sin hacer tantas consultas a la API) de traer los avatar de la tabla de las personas asociadas al cliente
    /*mounted() {
        return async function () {
            if(this.data)
                this.loadAvatarProfile(this.data.Code);
        };
    }

    loadAvatarProfile(Code) {
        let self = this;
        axios.get("/ecommerce/api/avatar/" + Code)
            .then((response) => {
                if (response.data.ok)
                    self.url = `..${response.data.url}`;
                else
                    self.url = response.data.url;
            })
            .catch((error) => {
                console.log('FAILURE!!', error);
            });
    }

    getMethods(){
        let supermethods= super.getMethods();
        supermethods['loadAvatarProfile']=this.loadAvatarProfile;
        return supermethods;
    }

    getData() {
        let superdata = super.getData();
        return function () {
            superdata['url']='';
            return superdata;
        }
    }*/

    getTemplate() {
        return `<img key='avatar ' :src="url" alt="" width="45" height="45" class="avatar img-fluid rounded-circle customer-image responsive-img" style="background: #6a6a6a">`;
    }
}

AvatarPersonsListComponent.registerComponent();

